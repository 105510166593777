import './ScrollDown.scss'

export function ScrollDown() {
    return (
        <>
            <div className='scroll'>
                <h6>swipe to scroll</h6>
                <div className='arrow'></div>
            </div>
        </>
    ) 
}

